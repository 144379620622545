@import url("https://fonts.googleapis.com/css?family=Rubik");
@import url("https://fonts.googleapis.com/css?family=Russo One");

.container,
.list {
  width: 100%;
  height: 100vh;
  background: url(../../Images/login-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.container img {
  width: 400px;
  margin-top: 100px;
}

.info {
  margin-top: 60px;
  color: #428bca;
  font-weight: bold;
}

.policy a {
  color: #428bca;
  text-decoration: none;
}

.policy a:hover {
  color: #0f3e66;
}

.loginDivider {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin: 10px auto 10px auto;
}

.login {
  position: relative;
  top: 5%;
  width: 400px;
  margin: auto auto auto auto;
  padding: 50px 50px 50px 50px;
  background-color: #428bca;
  border-radius: 50px;
}

.login h3 {
  color: #fff;
  font-family: "Rubik";
  font-size: 1.5em;
}

.login h5 {
  color: #fff;
  font-size: 1em;
}

.login button {
  margin-top: 30px;
}

.loginFields {
  width: 95%;
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
}

.loginFields input {
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 10px;
}

.listWrapper {
  position: relative;
  top: 5%;
  width: 400px;
  margin: auto auto auto auto;
  padding: 50px 50px 50px 50px;
  background-color: #428bca;
  border-radius: 40px;
}

.listWrapper {
  color: #fff;
  font-family: "Rubik";
}

.listImg {
  width: 400px;
  margin-top: 100px;
}

.brands {
  display: flex;
  flex-flow: wrap;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.listLogo {
  padding: 10px 0px 10px 0px;
}

.listLogo a {
  margin: 0px 5px 0px 5px;
  padding: 10px 0px 10px 0px;
  /* width: 200px; */
}

.loginButton {
  margin-top: 10px;
  margin-bottom: 30px;
  appearance: none;
  background-color: #fff;
  border: 2px solid #1a1a1a;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  min-height: 30px;
  min-width: 0;
  outline: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 30%;
  will-change: transform;
}

.loginButton:disabled {
  pointer-events: none;
}

.loginButton:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.loginButton:active {
  box-shadow: none;
  transform: translateY(0);
}

@media only screen and (max-width: 600px) {
  .login {
    width: 320px;
    height: 325px;
    padding: 20px;
    border-radius: 30px;
  }
  .loginButton {
    width: 100px;
    margin-bottom: 0px;
  }
  .container img {
    width: 75%;
  }

  .login h3 {
    font-size: 1.2em;
  }
  .loginDivider {
    margin: 10px 0px;
  }
  .loginFields {
    width: 90%;
  }
  .login button {
    margin-top: 15px;
  }
  .info {
    margin-top: 100px;
  }
}
