.services {
  width: 80%;
  margin: 50px auto 50px auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 40px 0;
  text-align: center;
}

.bgBox {
  background-color: #428bca;
  margin: auto;
  width: 100px;
  height: 100px;
  justify-content: center;
  border-radius: 20px;
  padding: 17px 0;
}

.serviceCard {
  margin: 10px 24px 10px 30px;
  justify-content: center;
}

.serviceImg1 {
  width: 60px;
  height: 70px;
}

.serviceImg2 {
  width: 60px;
  height: 70px;
}

.serviceImg3 {
  width: 60px;
  height: 70px;
}

.serviceImg4 {
  width: 60px;
  height: 70px;
}

.serviceImg5 {
  width: 60px;
  height: 70px;
}

.serviceText {
  margin-top: 10px;
  font-family: "Rubik";
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
