.carousel {
  height: 10px;
}

.nav {
  background: #fff;
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.12368697478991597) 11%,
    rgba(0, 0, 0, 0.3533788515406162) 43%,
    rgba(0, 0, 0, 0.5858718487394958) 100%
  ); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  /* background-color: #a5a9a4;*/
  width: 100%;
  height: 60px;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
}

.navLinks {
  display: flex;
  list-style: none;
  margin-top: 19px;
}

.navLink:hover {
  color: #428bca;
}

.divider {
  width: 2px;
  height: 30px;
  background-color: #000;
  /* margin: 0px 10px 0px 10px; */
}

.navLinks li {
  margin-right: 15px;
  margin-left: 15px;
}

.nav a {
  font-size: 1.3em;
  border-color: #fff;
  color: #000;
  text-decoration: none;
}

.nav img {
  width: 400px;
  /* padding-top: 5px; */
  padding-left: 100px;
}

.navList {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.navLinks {
  padding-right: 70px;
}

.login {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.login button {
  width: 95px;
  background-color: #428bca;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 20px;
  font-weight: 500;
  height: 35px;
  line-height: 14px;
  list-style: none;
  margin: -5px 100px 5px 0px;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.login button img {
  height: 40px;
  margin-left: -102px;
  margin-top: -13px;
}

.burger {
  display: none;
}

.bars {
  font-size: 40px;
  margin-top: 10px;
  margin-right: 30px;
}

.burgerMenuHidden {
  display: none;
}

.divider {
  width: 80%;
  height: 1px;
  background-color: #000;
}

.burgerMenuShown {
  background-color: #fff;

  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: 200px;
  display: block;
}

.burgerMenuShown ul {
  list-style: none;
}

.burgerMenuShown ul li {
  font-size: 1.5em;
}

.burgerMenuShown a {
  text-decoration: none;
  /* color: #fff; */
}

@media screen and (max-width: 900px) {
  .burger {
    display: block;
  }

  .navList {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .logo img {
    padding-left: 0px;
    width: 200px;
  }
}
