.contact {
  margin-top: 100px;
}

.contactTitle {
  text-align: center;
  padding-bottom: 20px;
}

.contactTitle h2 {
  font-family: "Russo One";
  padding-top: 60px;
}

.contactText {
  text-align: center;
  padding-bottom: 20px;
}

.contactText h2 {
  font-family: "Russo One";
  padding-top: 60px;
}

.cardContainer {
  display: flex;
  width: 50%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.contactCard {
  border-radius: 15px;
  width: 250px;
  height: 180px;
  background-color: #428bca;
  margin-top: 22px;
  text-align: center;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}

.contactCard h4 {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.3em;
}

.contactCard h3 {
  font-size: 1em;
}

.contactCardMain h4 {
  margin-top: 40px;
  margin-bottom: 50px;
  font-size: 1.3em;
}

.contactCardMain h3 {
  font-size: 1em;
}

.contactCardMain {
  z-index: 10;
  border-radius: 15px;
  margin-left: -50px;
  margin-right: -50px;
  width: 280px;
  height: 220px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
  background-color: #fff;
  text-align: center;
  color: #428bca;
}

.icon {
  background: #fff;
  width: 70px;
  height: 70px;
  margin: -40px auto 0px auto;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;
}

.icon img {
  width: 50px;
  margin: 10px 10px;
}

.map {
  width: 80%;
  margin: 20px auto;
  height: 400px;
  border: solid #428bca;
  border-radius: 15px;
  margin-bottom: 75px;
}

@media screen and (max-width: 1250px) {
  .cardContainer {
    width: 100%;
  }
}

@media screen and (max-width: 730px) {
  /* .contactCardMain {
    width: 250px;
  }

  .contactCardMain h3 {
    font-size: 1em;
  } */
  .cardContainer {
    flex-flow: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .contactCard {
    width: 100%;
  }
  .contactCardMain {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 410px) {
  .contactCardMain h3 {
    font-size: 1em;
  }
  .map {
    height: 250px;
  }
  .contactText {
    margin: auto;
  }
}

@media screen and (max-width: 280px) {
  .contactCardMain h3 {
    font-size: 0.8em;
  }
}
