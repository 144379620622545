.faq {
  margin-top: 100px;
  margin-bottom: 100px;
}

.faqTitle {
  text-align: center;
  padding-bottom: 20px;
}

.faqTitle h2 {
  font-family: "Russo One";
  padding-top: 60px;
}

/* .questionContainer {
  width: 470px;
  margin: 20px auto;
}

.questionCard {
  margin-bottom: 10px;
  /* height: 40px; 
}

.question {
  background-color: #428bca;
  color: #fff;
  border-radius: 10px;
  height: 50px;
}

.question img {
  width: 15px;
  float: left;
  margin: 17px 20px;
}

.question h3 {
  padding-top: 15px;
  font-size: 1.1em;
}

.answer {
  background-color: #fff;
  color: #428bca;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.answer img {
  padding-top: 10px;
  width: 20px;
  float: left;
  margin: 10px 20px;
}

.answer h3 {
  padding-top: 15px;
  font-size: 1.1em;
}

.answer p {
  font-family: "Rubik";
  font-weight: 500;
  color: #000;
  margin-left: 60px;
  padding-bottom: 10px;
}

@media screen and (max-width: 760px) {
  .questionContainer {
    width: 100%;
  }

  .question h3 {
    font-size: 1.2em;
    padding-top: 8px;
  }

  .answer h3 {
    font-size: 1.2em;
    padding-top: 8px;
  }
}

@media screen and (max-width: 506px) {
  .question h3 {
    font-size: 1em;
    padding-top: 8px;
  }
  .answer h3 {
    font-size: 1em;
    padding-top: 8px;
  }
}

@media screen and (max-width: 428px) {
  .question {
    height: 50px;
  }
} 
*/

.accordionContainer {
  padding-top: 70px;
  max-width: 28rem;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  color: rgb(9, 11, 79);
  transition: 0.6s ease-in-out;
  margin: auto;
}
.accordion {
  border-radius: 6px;
  width: 100%;
}
.accordionHeading {
  padding: 1rem;
  display: flex;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;
}
.accordionHeading > h3 {
  padding-top: 7px;
  font-weight: 600;
  font-size: 1rem;
}
.addIcon {
  transform-origin: center;
  transition: 0.2s ease-in-out;
}

.accordion:nth-child(1) {
  background-color: #428bca;
  border-left: 4px solid #428bca;
  color: white;
}
.accordion:nth-child(2) {
  background-color: #428bca;
  border-left: 4px solid #428bca;
  color: white;
}
.accordion:nth-child(3) {
  background-color: #428bca;
  border-left: 4px solid #428bca;
  color: white;
}

.accordion:nth-child(4) {
  background-color: #428bca;
  border-left: 4px solid #428bca;
  color: white;
}

.accordion:nth-child(5) {
  background-color: #428bca;
  border-left: 4px solid #428bca;
  color: white;
}

.accordion:nth-child(6) {
  background-color: #428bca;
  border-left: 4px solid #428bca;
  color: white;
}
.accordionContent {
  padding: 10px;
  padding-left: 3rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 600px) {
  .accordionContainer {
    width: 90%;
  }
} ;
