.headerList {
  display: flex;
  flex-flow: row wrap;
  margin: 40px 0px 20px 0px;
}

.brands {
  display: flex;
  flex-flow: wrap;
  width: 550px;
  padding-left: 30px;
  margin-left: 10%;
  margin-right: 10%;
}

/* .listLogo {
  width: 30px;
} */

.listLogo img {
  width: 80px;
}

.listLogo a {
  margin-left: 10px;
}

.services {
  font-family: "Rubik";
  font-size: 1.3em;
}

.services h4 {
  font-size: 1.5em;
  margin-left: 30px;
  margin-bottom: 20px;
}

.serviceList {
  /* list-style-image: url("../Images/tick.png"); */
  list-style: none;
}

.serviceList li {
  margin-bottom: 20px;
  font-weight: 500;
}

.listImg {
  width: 30px;
}
