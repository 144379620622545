body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Russo One", sans-serif; */
}

/* :root{
  font-family: 'Rubik', sans-serif !important;
} */
