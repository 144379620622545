.mainFooter {
  background-color: #fff;
  color: #000;
  border-top: solid #999;
  font-family: "Rubik";
}

.row {
  display: flex;
  width: 80%;
  margin: 0px auto;
}

.row b {
  color: #428bca;
}
.col {
  flex: 33.33%;
  width: 150px;
  margin: 10px 0;
  text-align: center;
}

.col ul {
  list-style: none;
  text-align: center;
  padding: 0;
}
.colSm {
  margin: auto;
  padding-bottom: 5px;
}

.colSm a {
  text-decoration: none;
}

.footerLogo {
  font-weight: bold;
}

.listUnstyled {
  font-family: "Rubik";
  font-weight: 700;
}

.footerImg {
  width: 300px;
}

.footerContact h4 {
  color: #428bca;
  font-family: "Russo One";
}

.footerMenu h4 {
  color: #428bca;
  font-family: "Russo One";
}

.footerSocial h4 {
  color: #428bca;
  font-family: "Russo One";
}

/* .footerSocial li {
  margin-top: 20px;
} */

.socialImg {
  width: 100px;
  margin-bottom: 15px;
}

.contactLink {
  text-decoration: none;
  color: #000;
}

.contactLink:hover {
  color: #000;
}

.policyLink {
  text-decoration: none;
  color: #000;
}

.policyLink:hover {
  color: #000;
}

@media screen and (max-width: 1067px) {
  .row {
    /* flex-flow: column; */
    width: 100%;
  }

  .container {
    /* width: 50%; */
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 850px) {
  .row {
    flex-flow: column;
  }
  .col {
    flex: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 402px) {
  .footerImg {
    width: 250px;
  }
  .row p {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
  }
}
