.policies {
  margin-top: 60px;
}

.policies h3 {
  font-family: "Russo One", sans-serif;
  text-transform: uppercase;
}

.policies h5 {
  font-family: "Russo One", sans-serif;
  text-transform: uppercase;
}

.policyNavbar {
  display: flex;
  color: #fff;
  width: 100%;
  height: 56px;
  background-color: #428bca;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.policyNavbarButton {
  margin: auto 10px;
  padding: 10px;
  cursor: pointer;
}

.privacyNavbarButton {
  margin: auto 10px;
  padding: 10px;
  cursor: pointer;
}

.termsContainer h3 {
  text-align: center;
}

.termsContainer p {
  margin-left: 40px;
  margin-right: 40px;
}

.termsContainer h5 {
  margin-left: 40px;
  font-weight: normal;
}

.privacyContainer h3 {
  text-align: center;
  margin-left: 40px;
}

.privacyContainer p {
  margin-left: 40px;
  margin-right: 40px;
}

.privacyContainer h5 {
  margin-left: 40px;
  font-weight: normal;
}

@media screen and (max-width: 536px) {
  .policyNavbar {
    height: 45.4px;
    font-size: 1em;
  }

  .policyNavbarButton {
    margin: auto;
  }
  .privacyContainer h3 {
    text-align: left;
    margin-left: 40px;
  }
}
